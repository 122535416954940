import React from "react";
import classNames from "classnames";
import cardBg from "@assets/img/cardBg.svg";
import {CardsSuit, CardTypeMap} from "@constants/Enums";

function CardListItem({card, ...restProps}) {
    return(<li className="cards-list-item" {...restProps}>
        <div className={classNames("flip-card", {active: card})}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={cardBg} alt={cardBg} />
                </div>
                <div className="flip-card-back">
                    <i className={`cardIcon icon-${card? (CardTypeMap[card.type] + CardsSuit[card.suit]) : ''}`}/>
                </div>
            </div>
        </div>
    </li>)
}

export default CardListItem