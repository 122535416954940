import { SET_GAME_IS_ACTIVE, SET_EDIT_GAME_IS_ACTIVE } from "@actions/actionTypes";

export default function gameIsActiveReducer(state = false, action) {
    switch (action.type) {
        case SET_EDIT_GAME_IS_ACTIVE:
            return !!action.isActive;
        case SET_GAME_IS_ACTIVE:
            return !!action.isActive;
        default:
            return state;
    }
}
