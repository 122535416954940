import React, {useState} from "react";
import { Modal, Tabs, Radio, Divider, Typography } from 'antd';
import {FormattedMessage} from "react-intl";
import clubIcon from "@assets/img/icon/suits/club.svg";
import diamondIcon from "@assets/img/icon/suits/diamond.svg";
import heartIcon from "@assets/img/icon/suits/heart.svg";
import spadeIcon from "@assets/img/icon/suits/spade.svg";
import {CardName, decksCount} from "@constants/Enums";
import classNames from "classnames";
const suits = [
    {
        name: "Clubs",
        icon: clubIcon
    },
    {
        name: "Diamonds",
        icon: diamondIcon
    },
    {
        name: "Hearts",
        icon: heartIcon
    },
    {
        name: "Spades",
        icon: spadeIcon
    }
]

const { Text } = Typography;

function CustomSelectModal({visible, onCancel, onConfirm}) {
    const [selectedCard, setSelectedCard] = useState("");
    const [deckNumber, setDeckNumber] = useState(1);

    const getDecksTemplate = () => {
        let template = [];
        for(let i = 1; i <= decksCount; ++i) {
            template.push(<Radio key={i} value={i}>{i}</Radio>);
        }
        return template;
    }

    return(
        <Modal title={<FormattedMessage id="select-card"/>}
               visible={visible}
               onOk={() => {setSelectedCard(""); onConfirm(selectedCard, deckNumber)}}
               onCancel={() => {setSelectedCard("");onCancel()}}
               centered={true}
               width={1000}
               okText={<FormattedMessage id="submit"/>}
               okButtonProps={{ disabled: !selectedCard || !deckNumber, className: "miniBtn" }}
               cancelButtonProps={{className: "miniBtn default"}}
               cancelText={<FormattedMessage id="cancel"/>}>
            <Tabs defaultActiveKey="1" centered className="customTab" >
                {
                    suits.map((suit, index) => (
                        <Tabs.TabPane tab={<img src={suit.icon} alt={suit.name}/>} key={index}>
                            <div className="custom-card-list">
                                {
                                    CardName.map(card => (
                                        <i className={classNames(`cardIcon icon-${card + suit.name.toLowerCase()}`, {active: selectedCard === card + suit.name.toLowerCase()})}
                                           onClick={() => setSelectedCard(card + suit.name.toLowerCase())}
                                           key={card + suit.name}/>
                                           )
                                    )
                                }
                            </div>

                        </Tabs.TabPane>
                    ))
                }
            </Tabs>
            <Divider dashed />
            <Text strong className="text-capitalize">
                <FormattedMessage id="please_select_deck_number"/>
            </Text><br/>
            <Radio.Group onChange={(e) => setDeckNumber(e.target.value)} value={deckNumber}>
                {
                    getDecksTemplate()
                }
            </Radio.Group>
        </Modal>
    )
}

export default CustomSelectModal