import React from "react";
import CardListItem from "@components/CardsList/CardListItem";
import classNames from "classnames";

function CardList({card, className}) {
    return(
        <ul className={classNames("cards-list", className)}>
            <CardListItem card={card}/>
        </ul>
    )
}

export default CardList