import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Scanner from "@components/Scanner/Scanner";
import {FormattedMessage} from "react-intl";
import {subscribeTickerWorker, unSubscribeTickerWorker, getTimeFromTickerWorker} from "@actions/workerActions";
import {playWarningSound} from "@services/soundServices";



function Timer({ subscribeTickerWorker, unSubscribeTickerWorker, getTimeFromTickerWorker, card}) {
    const [betDuration, setBetDuration] = useState()
    const [ticker, setTicker] = useState(undefined);

    useEffect(() => {
        function getServerTime(e) {
            const time = JSON.parse(e.data);
            setBetDuration(time.data)
        }
        subscribeTickerWorker(getServerTime);
        return () => {
            unSubscribeTickerWorker(getServerTime)
        }
    }, [])

    useEffect(() => {
        if (card) {
            setTicker(0)
        } else {
            setTicker(betDuration === undefined ? undefined : parseInt( betDuration / 1000))
        }

    }, [betDuration])

    return(<div className="ticker">
        <div className="tickerArea">{ ticker > 0 && ticker}</div>
        <Scanner ticker={ticker} />
    </div>)
}

function mapStateToProps(state) {
    return {
        card: state.card,
        winner: state.winner
    }
}

const mapDispatchToProps = {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    getTimeFromTickerWorker
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)