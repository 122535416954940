import {
    SET_CARD,
    SET_CARD_CODE
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import cards from "@constants/cards";
import {CardsSuit} from "@constants/Enums"

export default function cardReducer(state = initialStore.card, action) {
    switch (action.type) {
        case SET_CARD_CODE:
            return action.code? action.code : initialStore.card;
        default:
            return state;
    }
}